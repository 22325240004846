import * as React from 'react';

import facebook from '../../../images/facebook-logo.svg';
import twitter from '../../../images/twitter-logo.svg';
import instagram from '../../../images/intagram-logo.svg';

const Footer: React.FC = () => {
	return (
		<footer className={'bg-[#1B5AA1] text-white'}>
			<div className={'container mx-auto py-16 px-6'}>
				<div className="mx-auto flex flex-col items-center justify-center">
					<div className="flex justify-center gap-8">
						<a
							href="https://www.facebook.com/briteworxcw/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src={facebook}
								alt="facebook"
								width={35}
								height={35}
							/>
						</a>
						<a
							href="http://twitter.com/BWXCW"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src={twitter}
								alt="twitter"
								width={35}
								height={35}
							/>
						</a>
						<a
							href="https://www.instagram.com/briteworxcw/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src={instagram}
								alt="instagram"
								width={35}
								height={35}
							/>
						</a>
					</div>
					<div className="mt-5 flex gap-14 font-roboto">
						<a
							href="https://briteworx.com/privacy-policy"
							className="text-lg tracking-wide text-white underline underline-offset-4 hover:text-white hover:underline"
						>
							Privacy Policy
						</a>
						<a
							href="https://briteworx.com/contact"
							className="text-lg tracking-wide text-white underline underline-offset-4 hover:text-white hover:underline"
						>
							Contact Us
						</a>
					</div>
					<p className="mt-12 text-base font-semibold text-white">
						© BriteWorX {new Date().getFullYear()}. All rights
						reserved.
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
