import * as React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import Layout from '../../layouts/BriteWorxLayout';
import Banner from '../../components/briteworx/Banner/Banner';
import JobSearch from '../../components/briteworx/JobSearch/JobSearch';

const SearchPage: React.FC<PageProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	function filterByBrand(edges: any[], brandToFilter: string) {
		return edges.filter(
			(page) => page.node.pageContext.brand === brandToFilter
		);
	}

	data.allSitePage.edges = filterByBrand(
		data.allSitePage.edges,
		'Brite WorX'
	);

	return (
		<Layout
			headerType="light"
			title="Brite WorX | Employment Opportunities"
			desc="Brite WorX | Employment Opportunities"
		>
			<Banner />
			<section className={'bg-[#B6E2EC]'}>
				<div className={'container mx-auto py-16 px-6'}>
					<p className="mx-auto max-w-[820px] text-center font-hind text-xl font-medium text-[#1B5AA1]">
						Because we’re family owned, we operate family-friendly
						locations. We are an equal opportunity employer and
						operate a drug-free work environment. We are focused on
						the safety of our team members and our customers —
						adhering to the required environmental guideline and
						emphasizing safety in the workplace every day. Our
						schedules are flexible, and the pay and benefits are
						competitive.
					</p>
					<p className="mx-auto max-w-[820px] text-center font-hind text-xl font-medium text-[#1B5AA1]">
						Get Paid Weekly – Your Hard Work, Rewarded Faster!
					</p>
				</div>
			</section>
			<JobSearch data={data} />
		</Layout>
	);
};
export default SearchPage;
