import * as React from 'react';
import { Search, Dropdown } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import './index.css';

const getJobData = (data: any) => {
	let _tempJobs = [];
	let allJobs = data?.allSitePage?.edges;
	for (let job in allJobs) {
		if (allJobs[job]?.node?.pageContext) {
			if (allJobs[job]?.node?.pageContext?.title) {
				_tempJobs.push(allJobs[job]?.node?.pageContext);
			}
		}
	}
	return _tempJobs;
};

interface JobSearchProps {
	data: any;
}
const removeAccents = (str: any) => {
	const accents = {
		a: 'àáâãäåæ',
		c: 'ç',
		e: 'èéêëæ',
		i: 'ìíîï',
		n: 'ñ',
		o: 'òóôõöø',
		s: 'ß',
		u: 'ùúûü',
		y: 'ÿ'
	};
	let newStr = '';
	for (let char in str) {
		if (str.hasOwnProperty(char)) {
			let continueFlag = false;
			for (let accent in accents) {
				if (accents.hasOwnProperty(accent)) {
					if ((accents as any)[accent].includes(str[char])) {
						newStr += accent;
						continueFlag = true;
						break;
					}
				}
			}
			if (continueFlag) continue;
			newStr += str[char];
		}
	}
	return newStr;
};

const JobSearch: React.FC<JobSearchProps> = (props: JobSearchProps) => {
	const _jobs = getJobData(props.data);
	const [jobs, setJobs] = useState(_jobs);
	const [displayedJobs, setDisplayedJobs] = useState(_jobs);
	const [searchTerm, setSearchTerm] = useState<string>('');

	const [categories, setCategories] = useState();
	const [jobTypes, setJobTypes] = useState();
	const [states, setStates] = useState();
	const [cities, setCities] = useState();

	const [selectedCategories, setSelectedCategories] = useState<any>();
	const [selectedJobTypes, setSelectedJobTypes] = useState<any>();
	const [selectedStates, setSelectedStates] = useState<any>();
	const [selectedCities, setSelectedCities] = useState<any>();
	const searchTerms = [
		'title',
		'postalCode',
		'reqNumber',
		'category',
		'fullTime',
		'storeNumber',
		'address',
		'city',
		'state',
		'fullAddress',
		'fullStateName'
	];

	const isValidUSZip = (sZip: string): boolean => {
		return /^\d{5}(-\d{4})?$/.test(sZip);
	};

	const query = (
		jobs: any,
		searchTerm: string,
		searchTerms: string[],
		states: [],
		cities: [],
		categories: [],
		types: []
	) => {
		searchTerm = searchTerm.toLowerCase();
		let _searchResults = [];

		for (const job in jobs) {
			// States
			if (states && states.length > 0) {
				let addJob = false;
				for (let state in states) {
					if (String(jobs[job]?.state) === states[state])
						addJob = true;
				}
				if (!addJob) continue;
			}

			// Cities
			if (cities && cities.length > 0) {
				let addJob = false;
				for (let city in cities) {
					if (String(jobs[job]?.city) === cities[city]) addJob = true;
				}
				if (!addJob) continue;
			}

			// Category
			if (categories && categories.length > 0) {
				let addJob = false;
				for (let category in categories) {
					if (String(jobs[job]?.category) === categories[category])
						addJob = true;
				}
				if (!addJob) continue;
			}

			// Types
			if (types && types.length > 0) {
				let addJob = false;
				for (let fullTime in types) {
					if (String(jobs[job]?.fullTime) === types[fullTime])
						addJob = true;
				}
				if (!addJob) continue;
			}

			if (isValidUSZip(searchTerm) && jobs[job]?.nearbyZipCodes) {
				if (jobs[job].nearbyZipCodes.includes(searchTerm)) {
					_searchResults.push(jobs[job]);
					continue;
				}
			}

			// Term
			for (let target in searchTerms) {
				if (jobs[job].hasOwnProperty(searchTerms[target])) {
					if (typeof searchTerm === 'object') {
						for (let t in searchTerms) {
							if (searchTerm[t] === '') continue;
							if (
								removeAccents(
									jobs[job][searchTerms[target]]
										.toString()
										.toLowerCase()
								).includes(searchTerm[t])
							) {
								_searchResults.push(jobs[job]);
								break;
							}
						}
					} else {
						if (
							removeAccents(
								jobs[job][searchTerms[target]]
									.toString()
									.toLowerCase()
							).includes(searchTerm) ||
							searchTerm === ''
						) {
							_searchResults.push(jobs[job]);
							break;
						}
					}
				}
			}
		}
		return _searchResults;
	};
	useEffect(() => {
		setDisplayedJobs(
			query(
				jobs,
				searchTerm,
				searchTerms,
				selectedStates,
				selectedCities,
				selectedCategories,
				selectedJobTypes
			)
		);
	}, [
		searchTerm,
		selectedStates,
		selectedCities,
		selectedCategories,
		selectedJobTypes
	]);

	useEffect(() => {
		let tempCategories: any = [];
		let tempJobTypes: any = [];
		let tempStates: any = [];
		let tempCities: any = [];
		for (const job in jobs) {
			if (jobs.hasOwnProperty(job)) {
				if (
					!tempCategories.some(
						(e: any) => e.key === jobs[job].category
					)
				) {
					const _category = jobs[job].category;
					tempCategories.push({
						key: _category,
						text: _category,
						value: _category
					});
				}
			}
			if (jobs.hasOwnProperty(job)) {
				if (
					!tempJobTypes.some((e: any) => e.key === jobs[job].fullTime)
				) {
					const _jobType = jobs[job].fullTime;
					tempJobTypes.push({
						key: _jobType,
						text: _jobType,
						value: _jobType
					});
				}
			}
			if (jobs.hasOwnProperty(job)) {
				if (!tempStates.some((e: any) => e.key === jobs[job].state)) {
					const _state = jobs[job].state;
					tempStates.push({
						key: _state,
						text: _state,
						value: _state
					});
				}
			}
			if (jobs.hasOwnProperty(job)) {
				if (!tempCities.some((e: any) => e.key === jobs[job].city)) {
					const _city = jobs[job].city;
					tempCities.push({
						key: _city,
						text: _city,
						value: _city
					});
				}
			}
		}
		setCategories(tempCategories);
		setCities(tempCities);
		setJobTypes(tempJobTypes);
		setStates(tempStates);
	}, [jobs]);

	useEffect(() => {
		const url = new URL(window.location.href);
		const term = url.searchParams.get('term');
		if (term) {
			setSearchTerm(term);
		}
		const _state = url.searchParams.get('state');
		if (_state) {
			setSelectedStates([_state]);
		}
		const _category = url.searchParams.get('category');
		if (_category) {
			setSelectedCategories([_category]);
		}
		const _type = url.searchParams.get('type');
		if (_type) {
			setSelectedJobTypes([_type]);
		}
	}, []);

	const onStateDropdownChangeHandler = (e: any, res: any) => {
		setSelectedStates(res.value);
	};
	const onCityDropdownChangeHandler = (e: any, res: any) => {
		setSelectedCities(res.value);
	};
	const onCategoryDropdownChangeHandler = (e: any, res: any) => {
		setSelectedCategories(res.value);
	};
	const onJobTypeDropdownChangeHandler = (e: any, res: any) => {
		setSelectedJobTypes(res.value);
	};

	return (
		<section className={'job-search bg-[#F4EBCB] px-5 py-12'}>
			<div className={'container mx-auto flex flex-col px-6 lg:flex-row'}>
				<div
					className={
						'search-fields flex w-full flex-col gap-3 lg:w-1/4'
					}
				>
					<p className={'text-xl font-bold'}>Filters</p>
					<Search
						showNoResults={false}
						name="term"
						onSearchChange={(e: any) => {
							setSearchTerm(e.target.value);
						}}
						label=""
						className={'search-term max-lg:w-full'}
						value={searchTerm}
						placeholder={'Search: Zip Code, Title, ...'}
					/>
					<Dropdown
						placeholder="State"
						className={'w-full max-w-full'}
						onChange={onStateDropdownChangeHandler}
						value={selectedStates}
						selection
						clearable
						multiple
						search
						options={states}
					/>
					<Dropdown
						placeholder="City"
						className={'w-full max-w-full'}
						onChange={onCityDropdownChangeHandler}
						value={selectedCities}
						selection
						multiple
						clearable
						search
						options={cities}
					/>
					<Dropdown
						placeholder="Category"
						className={'w-full max-w-full'}
						onChange={onCategoryDropdownChangeHandler}
						value={selectedCategories}
						selection
						clearable
						multiple
						search
						options={categories}
					/>
					<Dropdown
						placeholder="Job Type"
						className={'w-full max-w-full'}
						onChange={onJobTypeDropdownChangeHandler}
						value={selectedJobTypes}
						selection
						clearable
						multiple
						search
						options={jobTypes}
					/>
				</div>
				<div
					className={
						'flex flex-col gap-3 max-lg:pt-12 lg:w-3/4 lg:pl-5'
					}
				>
					<p className={'text-xl font-bold'}>
						Results: {displayedJobs.length}
					</p>
					{displayedJobs.map((job) => {
						return (
							<a
								key={'job-' + job.reqNumber}
								href={job.jobPath}
								className={
									'flex min-h-[150px] w-full cursor-pointer flex-col justify-between rounded border-2 border-[#D9C684] bg-white p-5 px-8 max-lg:py-8 lg:flex-row'
								}
							>
								<div
									className={
										'flex flex-col justify-center font-futura'
									}
								>
									<span
										className={
											'text-2xl font-bold text-[#1B5AA1]'
										}
									>
										{job.title}
									</span>
									<span
										className={
											' font-futura text-base text-black'
										}
									>
										{job.fullAddress}
									</span>
									<span
										className={
											' text-xl font-bold text-black'
										}
									>
										{job.category}
									</span>
								</div>
								<div
									className={
										'flex min-w-[200px] flex-col items-center  justify-center max-lg:pt-12'
									}
								>
									<button
										className={
											'rounded-md border border-[#2D5A9C] bg-[#2D5A9C] px-9 py-3 font-roboto-condensed text-xl font-medium text-white transition-all hover:bg-white hover:text-[#2D5A9C]'
										}
									>
										APPLY NOW
									</button>
								</div>
							</a>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default JobSearch;
